import { layouts } from "./utils/styles";
import { useState, useEffect } from 'react';

const Layout = ({mapLayout,mapSize, ...props}) => {

    const [ textSize, setTextSize ] = useState({
        headline: '',
        tagline: '',
        subtitle: '',
        blockWidth:'',
    });


    useEffect(()=> {
        switch(mapSize) {
            case 0:
                // setTextSize({headline: 'text-[3.5em]', tagline: 'text-[2em]', subtitle: 'text-[1.5em]', blockWidth: 'min-w-[480px]'})
                setTextSize({headline: 'text-[4.6667em]', tagline: 'text-[2.66667em]', subtitle: 'text-[2em]', blockWidth: 'min-w-[640px]'})
                break;
            case 1:
                setTextSize({headline: 'text-[4.6667em]', tagline: 'text-[2.66667em]', subtitle: 'text-[2em]', blockWidth: 'min-w-[640px]'})
                break;
            case 2:
                // setTextSize({headline: 'text-[5.25em]', tagline: 'text-[3em]', subtitle: 'text-[2.25em]',blockWidth: 'min-w-[720px]'})
                setTextSize({headline: 'text-[4.6667em]', tagline: 'text-[2.66667em]', subtitle: 'text-[2em]',blockWidth: 'min-w-[640px]'})
                break;
            case 3:
                setTextSize({headline: 'text-[7em]', tagline: 'text-[4em]', subtitle: 'text-[3em]', blockWidth: 'min-w-[960px]'})
                break;
            default:
                break;
        }
    }, [mapSize])

    const layout = layouts.find(l => l.id === mapLayout)

    if (!layout) return null;

    return layout.component({ ...props, textSize });
}

export default Layout;