import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import App from "./App";
import Container from "./PosterDesigner/container";
import Home from './Home/Home';
import ContactPage from "./ContactUs/ContactUsPage";
import AboutPage from "./About/AboutPage";
import ShippingAndReturns from "./Info/ShippingAndReturns";
import TermsAndConditions from './Info/TermsAndConditions';
const Router = () => {

    const appRouter = createBrowserRouter(createRoutesFromElements(
        <>
            <Route path='/' element={<App />}>
                <Route index element={<Home />}/>
                <Route path='contact-us' element={<ContactPage/>}/>
                <Route path='about-us' element={<AboutPage/>}/>
                <Route path='poster' element={<Container/>}/>
                <Route path='shipping-returns' element={<ShippingAndReturns/>}/>
                <Route path='terms-conditions' element={<TermsAndConditions/>}/>
            </Route>
        </>
    ))
    return (
        <>
        <RouterProvider router={appRouter}/>
        </>
    )
}

export default Router;