const TermsAndConditions = () => {
    return (
        <>
        <div className="flex flex-col items-center bg-accent/30 p-6 min-h-screen">
            <div className="w-full h-48 bg-cover rounded-sm mb-10">
                <div className="flex items-center justify-center w-full h-full">
                    <h1 className="text-3xl text-text font-semibold">Terms & Conditions</h1>
                </div>
            </div>
            <div className="text-text w-4/5 md:w-3/5 lg:w-1/2 mx-auto mb-20">
                <div className="mb-6">
                    <p className="text-2xl font-medium">Welcome to Globe & Map</p>
                </div>
                <div className="mb-4">
                    <p>These Terms & Conditions govern your use of our platform. By accessing or using our services, you agree to be bound by these terms. If you do not agree, please do not use our platform.</p>
                </div>

                <div className="mb-6">
                    <p className="text-xl font-medium">Platform Name</p>
                </div>
                <div className="mb-4">
                    <p><span className="font-medium">Globe & Map</span> is a platform specializing in creating customized city map posters. Our maps are rendered using data and services provided by Mapbox and OpenStreetMap.</p>
                </div>

                <div className="mb-6">
                    <p className="text-xl font-medium">Usage Scope</p>
                </div>
                <div className="mb-4">
                    <p>Our platform offers customized map posters, allowing users to select a location, customize styles, and order digital or printed artwork. The platform is intended for personal use only, and commercial redistribution of our products is strictly prohibited.</p>
                </div>

                <div className="mb-6">
                    <p className="text-xl font-medium">User Responsibilities</p>
                </div>
                <div className="mb-4">
                    <p>As a user, you are responsible for:</p>
                    <ul className="list-disc pl-5">
                        <li>Providing accurate information during the customization and checkout process.</li>
                        <li>Ensuring compliance with all applicable laws when using our services.</li>
                        <li>Not using the platform for any unlawful or unauthorized purposes.</li>
                    </ul>
                </div>

                <div className="mb-6">
                    <p className="text-xl font-medium">Pricing</p>
                </div>
                <div className="mb-4">
                    <p>All prices listed on the platform are in your local currency unless stated otherwise. Prices are subject to change without prior notice. Taxes and shipping fees (where applicable) will be calculated and displayed at checkout.</p>
                </div>

                <div className="mb-6">
                    <p className="text-xl font-medium">Liabilities</p>
                </div>
                <div className="mb-4">
                    <p>We strive to provide accurate previews of customized posters. However, slight variations in color and design may occur due to printing or display limitations. <span className="font-medium">Globe & Map</span> is not responsible for errors resulting from incorrect information entered by the user.</p>
                </div>

                <div className="mb-6">
                    <p className="text-xl font-medium">Copyright</p>
                </div>
                <div className="mb-4">
                    <p>All map designs and content generated on the platform are the intellectual property of <span className="font-medium">Globe & Map</span>. Users retain ownership of any personal data or input provided but grant us a non-exclusive license to use this data to fulfill and promote orders.</p>
                </div>

                <div className="mb-6">
                    <p className="text-xl font-medium">Jurisdiction</p>
                </div>
                <div className="mb-4">
                    <p>These Terms & Conditions are governed by the laws of Canada. Any disputes arising from your use of the platform will be resolved under the jurisdiction of Canadian courts.</p>
                </div>

                <div className="mb-6">
                    <p className="text-xl font-medium">Updates</p>
                </div>
                <div className="mb-4">
                    <p>We reserve the right to modify these Terms & Conditions at any time. Updates will be posted on this page, and continued use of the platform constitutes acceptance of the revised terms.</p>
                </div>

                <div className="mb-6">
                    <p className="text-xl font-medium">Contact Us</p>
                </div>
                <div className="mb-4">
                    <p>If you have any questions about these Terms & Conditions, please contact us at <a href="mailto:support@globeandmap.com" className="text-primary font-medium">support@globeandmap.com</a>.</p>
                </div>
            </div>
        </div>
        </>
    );
};

export default TermsAndConditions;
