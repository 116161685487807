import { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import './map.css';
import Layout from "./Layout";
import { sizes } from './utils/styles'
import { styles } from "./utils/styles";

const Map = ({mapContainerRef, orientation, mapData, mapLocation, headline, tagline, 
              subtitle, labels, mapLayout, mapSize, scale, setScale, size, setSize, mapStyle, mapFont}) => {

  const [colors, setColors ] = useState({text: '', background: ''})
  
  const calculateScale = (width, min, max) => {
    const minWidth = 320
    const maxWidth = 8000
    return (((max-min)/(maxWidth-minWidth))*width) + min;
  }

  useEffect(() => {
    const currentSize = sizes[mapSize] || {};
  
    let minScale, maxScale;
    if (orientation === 'landscape') {
      minScale = currentSize.minLandScale;
      maxScale = currentSize.maxLandScale;
    } else if (orientation === 'portrait') {
      minScale = currentSize.minPortScale;
      maxScale = currentSize.maxPortScale;
    }
  
    
    setSize({
      width: currentSize.width,
      height: currentSize.height,
      minScale: minScale,
      maxScale: maxScale,
    });
  }, [mapSize, orientation, setSize]); 
  
  
  useEffect(() => {
    // Calculate scale and set styles when size changes
    const handleScale = () => {
      const screenWidth = window.innerWidth;
      if (size.minScale !== null && size.maxScale !== null) {
        const newScale = calculateScale(screenWidth, size.minScale, size.maxScale);
        setScale(newScale);
      }
    };
  
    handleScale();

    window.addEventListener('resize', handleScale);
    return () => window.removeEventListener('resize', handleScale);

  }, [size, orientation, setScale]);


  useEffect(() => {
    const updateControlScale = () => {
      const controls = document.querySelectorAll(".mapboxgl-ctrl");
      controls.forEach((control) => {
        const baseScale = 1 / scale;
        const dampingFactor = 0.5 + 0.5 * scale; 
        const adjustedScale = baseScale * dampingFactor;
        control.style.transform = `scale(${adjustedScale})`;
        control.style.transformOrigin = "top right";
        
      });
    };
  
    updateControlScale();
  }, [scale, mapSize]);

  useEffect(() => {
    const selectedStyle = styles.find(style => style.styleUrl === mapStyle);

    if (selectedStyle && selectedStyle.colors) {
        const textColor = selectedStyle.colors.primary;
        const backgroundColor = selectedStyle.colors.secondary;
        
        setColors(prevColors => ({
            ...prevColors,
            text: textColor || prevColors.text, 
            background: backgroundColor || prevColors.background 
        }));
    }
}, [mapStyle]);


  return (
    <>
     <div className="relative flex justify-center items-center w-full overflow-hidden h-[540px] md:h-[720px] lg:h-screen">
      {/* <div className="absolute top-0 right-0 m-3 p-2 z-10">
        <ScaleSlider scale={scale} setScale={setScale} size={size}/>
      </div> */}
      {/* transform: `scale(${scale})` */}
      <div id='container' style={{ transform: `scale(${scale})`, transformOrigin: 'center' }} >
      {/* ${orientation === 'landscape'? landscapeStyle : portraitStyle} */}
        <div className={`relative bg-white shadow-black shadow-2xl`} style={{
    width: orientation === 'landscape' ? `${size.height}px` : `${size.width}px`,
    height: orientation === 'landscape' ? `${size.width}px` : `${size.height}px`,
  }}>
        <div id='map-canvas' className="relative flex h-full w-full">
        <div className="h-full w-full">
          <div id="map-container" ref={mapContainerRef} className="w-full h-full" />
        </div>
        <Layout 
          mapLayout={mapLayout} 
          labels={labels} 
          headline={headline} 
          tagline={tagline} 
          subtitle={subtitle} 
          mapData={mapData} 
          mapLocation={mapLocation} 
          mapSize={mapSize} 
          colors={colors}
          mapFont={mapFont}
        />
        
             </div>
            </div>
          </div>
        </div>
    </>
  );
}


export default Map;

