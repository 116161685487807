const ShippingAndReturns = () => {
    return (
        <>
        <div className="flex flex-col items-center bg-accent/30 p-6 min-h-screen">
            <div className="w-full h-48 bg-cover rounded-sm mb-10">
                <div className="flex items-center justify-center w-full h-full">
                    <h1 className="text-3xl text-text font-semibold">Shipping & Returns Policy</h1>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center gap-6 w-full">
                <div className="text-text w-4/5 md:w-3/5 lg:w-1/2 mx-auto mb-20">
                    <div className="mb-6">
                        <p className="text-2xl font-medium">Shipping</p>
                    </div>
                    <div className="mb-4">
                        <p>All orders are processed within 2 to 4 business days after receiving your order confirmation email. You will receive another notification after your order has been shipped.</p>
                    </div>
                    <div className="mb-4">
                        <p>The shipping time depends on your location, but can be estimated as follows:</p>
                    </div>
                    <div className="mb-4">
                        <p><span className="font-medium">USA: </span>3 - 4 business days</p>
                        <p><span className="font-medium">Canada: </span>4 - 7 business days</p>
                        <p><span className="font-medium">Europe: </span>6 - 8 business days</p>
                        <p><span className="font-medium">Australia: </span>2 - 14 business days</p>
                        <p><span className="font-medium">Japan: </span>4 - 8 business days</p>
                        <p><span className="font-medium">International: </span>10 - 20 business days</p>
                    </div>
                    <div className="mb-8">
                        <p>Your order may be subject to import duties and taxes (including VAT), which are incurred once a shipment reaches your destination country. <span className="font-medium">globe & map</span> is not responsible for these charges if they are applied and are your responsibility as the customer.</p>
                    </div>
                    <div className="mb-6">
                        <p className="text-2xl font-medium">Returns & Refunds</p>
                    </div>
                    <div className="mb-4">
                        <p>Due to the customized nature of our digital artwork and printed map posters, we are unable to accept returns or exchanges once an order has been processed and delivered.</p>
                    </div>
                    <div className="mb-4">
                        <p>If you receive a damaged or incorrect product, please contact us within 30 days of delivery with your order details.</p>
                    </div>
                    <div className="mb-4">
                        <p><span className="font-medium">Refunds:</span> Refunds are only applicable in cases where:</p>
                        <ul className="list-disc pl-5">
                            <li>A product was damaged during shipping.</li>
                            <li>An incorrect product was delivered.</li>
                            <li>A technical issue prevents delivery of a digital product.</li>
                        </ul>
                    </div>
                    <div className="mb-4">
                        <p>Refunds will be processed within 7-10 business days after approval and will be credited back to the original payment method.</p>
                    </div>
                    <div className="mb-4">
                        <p>Please note that we do not offer refunds for customer errors, including incorrect spelling, inaccurate details provided during customization, 
                            or dissatisfaction with the final design after approval.</p>
                    </div>
                    <div className="mb-4">
                        <p><span className='font-medium'>Notification for EU consumers: </span>According to Article 16(c) and (e) of the Directive 2011/83/EU of the 
                        European Parliament and of the Council of 25 October 2011 on consumer rights, the right of withdrawal may not be provided for:</p>
                        <ol className="list-decimal pl-5">
                            <li>the supply of goods that are made to the consumer's specifications or are clearly personalized</li>
                            <li>sealed goods which were unsealed after delivery and thus aren't suitable for return due to health protection or hygiene reasons</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default ShippingAndReturns;
